import { createTheme } from '@mui/material';
import { BASE_COLORS, WHITE_LABEL_BASE_COLORS } from './constants';

const getThemeDefinition = (baseColors) => {
    return {
        typography: {
            fontFamily: 'Open Sans',
            h1: {
                fontSize: 48,
                fontWeight: 700,
            },
            h2: {
                fontSize: 40,
                fontWeight: 700,
            },
            h3: {
                fontSize: 32,
                fontWeight: 700,
            },
            h4: {
                fontSize: 24,
                fontWeight: 700,
            },
            h5: {
                fontSize: 18,
                fontWeight: 700,
            },
            h6: {
                fontSize: 16,
                fontWeight: 700,
            },
            body1: {
                fontSize: 18,
                fontWeight: 400,
            },
            body2: {
                fontSize: 14,
                fontWeight: 400,
            },
        },
        palette: {
            primary: {
                main: baseColors.PRIMARY_MAIN,
            },
            secondary: {
                main: baseColors.SECONDARY_MAIN,
            },
            background: {
                default: baseColors.BACKGROUND_DEFAULT,
            },
        },
        shape: {
            borderRadius: 8,
        },
        components: {
            MuiAccordionDetails: {
                styleOverrides: {
                    root: {
                        padding: 0,
                        margin: '8px 0',
                        '& .connecting-item': {
                            cursor: 'pointer',
                            fontSize: 14,
                            color: baseColors.TEXT_BLACK_COLOR,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '8px 16px',
                            '& svg': {
                                color: baseColors.PRIMARY_MAIN,
                            },
                            '&:hover, &.active': {
                                backgroundColor: baseColors.BACKGROUND_LIGHT_RED,
                            },
                        },
                    },
                },
            },
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        '&.MuiAccordionSummary-root': {
                            minHeight: 'unset',
                            borderBottom: 0,
                            '& svg': {
                                color: baseColors.PRIMARY_MAIN,
                                '&:hover': {
                                    borderRadius: 4,
                                    backgroundColor: baseColors.BACKGROUND_WHITE,
                                },
                            },
                        },
                        '&.MuiAccordionSummary-root.Mui-expanded': {
                            minHeight: 'unset',
                            borderBottom: `1px solid ${baseColors.BACKGROUND_LIGHT_GRAY}`,
                        },
                        '& .MuiAccordionSummary-content.Mui-expanded': {
                            margin: '12px 0',
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        '&.link-button': {
                            textTransform: 'none',
                            color: baseColors.TEXT_BLUE,
                            padding: 0,
                            '&:hover': {
                                backgroundColor: 'unset',
                            },
                        },
                        '&.map-controller-button': {
                            textTransform: 'none',
                            color: baseColors.WHITE,
                            boxShadow: 'unset',
                            '&>span:after': {
                                display: 'inline-block',
                                content: '""',
                                width: '8px',
                                height: '8px',
                                backgroundColor: 'inherit',
                                borderRadius: '50%',
                                marginLeft: '5px',
                                marginBottom: '1px',
                            },
                            '&>span.has-items-checked:after': {
                                backgroundColor: baseColors.WHITE,
                            },
                        },
                    },
                },
            },
            MuiAppBar: {
                defaultProps: {
                    color: 'inherit',
                },
                styleOverrides: {
                    root: {
                        backgroundColor: baseColors.PRIMARY_MAIN,
                        color: baseColors.WHITE,
                        '& .MuiInputLabel-root': {
                            color: `white !important`,
                        },
                        '& .MuiInputBase-root': {
                            borderColor: 'white',
                            color: 'inherit',
                        },
                        '& .MuiSelect-icon': {
                            fill: 'black',
                        },
                        '& .Mui-focused': {
                            color: 'white',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: `white !important`,
                        },
                        '& .MuiSvgIcon-root': {
                            color: `black`,
                        },
                        '& .MuiTypography-root': {
                            color: `white !important`,
                        },
                        '& .MuiAutocomplete-root': {
                            '& .Mui-focused': {
                                borderBottomLeftRadius: '0',
                                borderBottomRightRadius: '0',
                                borderBottom: '1px solid grey',
                            },
                        },
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        '&.MuiMenu-paper': {
                            backgroundColor: baseColors.WHITE,
                        },
                        '&.basemap-menu': {
                            position: 'absolute',
                            backgroundColor: baseColors.WHITE,
                            zIndex: 100,
                            borderRadius: '10px',
                            padding: '10px 5px',
                        },
                        '&.basemap-controller': {
                            padding: '10px',
                            marginRight: '8px',
                        },
                        '&.map-controller': {
                            background: baseColors.PRIMARY_MAIN,
                            color: baseColors.WHITE,
                            padding: '8px',
                            borderTopLeftRadius: '8px',
                            borderTopRightRadius: '8px',
                        },
                        '&.map-controller-dropdown': {
                            position: 'relative',
                            zIndex: 1100,
                            padding: '20px',
                            backgroundColor: baseColors.PRIMARY_MAIN,
                            color: baseColors.WHITE,
                            marginTop: '20px',
                            '& .dropdown-level-1': {
                                width: '230px',
                                borderRight: `1px solid ${baseColors.WHITE}`,
                                paddingRight: '20px',
                                '&.no-border': {
                                    border: 0,
                                },
                                '&.mobile': {
                                    width: '100%',
                                    borderRight: 0,
                                    borderBottom: `1px solid ${baseColors.WHITE}`,
                                    paddingRight: 0,
                                    paddingBottom: '16px',
                                },
                            },
                            '& .dropdown-level-2': {
                                width: '230px',
                                paddingLeft: '20px',
                                '&.mobile': {
                                    width: '100%',
                                    paddingTop: '16px',
                                    paddingLeft: 0,
                                    marginLeft: '12px',
                                },
                            },
                        },
                        '&.map-controller-dropdown:after': {
                            content: '""',
                            position: 'absolute',
                            top: '5px',
                            left: '32px',
                            marginLeft: '-15px',
                            marginTop: '-15px',
                            // position: 'relative',
                            zIndex: 1,
                            height: 0,
                            borderBottom: `solid 15px ${baseColors.PRIMARY_MAIN}`,
                            borderLeft: 'solid 15px transparent',
                            borderRight: 'solid 15px transparent',
                        },
                    },
                },
            },
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        '&.map-controller-checkbox': {
                            color: baseColors.WHITE,
                            padding: '0 8px 0 0',
                            '&.Mui-checked': {
                                color: baseColors.WHITE,
                            },
                            '&.Mui-disabled': {
                                color: baseColors.GRAY,
                            },
                        },
                    },
                },
            },
            MuiRadio: {
                styleOverrides: {
                    root: {
                        '&.map-controller-radio': {
                            color: baseColors.WHITE,
                            padding: '0 8px 0 0',
                            '&.Mui-checked': {
                                color: baseColors.WHITE,
                            },
                            '&.Mui-disabled': {
                                color: baseColors.GRAY,
                            },
                        },
                    },
                },
            },
            MuiAutocomplete: {
                styleOverrides: {
                    popper: {
                        backgroundColor: baseColors.WHITE,
                    },
                },
            },
            MuiSelect: {
                styleOverrides: {
                    root: {
                        '&.year-selector': {
                            backgroundColor: 'white',
                            '&.Mui-focused': {
                                color: baseColors.PRIMARY_MAIN,
                            },
                        },
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        '&.map-controller-arrow': {
                            color: baseColors.WHITE,
                            '&.Mui-disabled > svg': {
                                color: `${baseColors.GRAY} !important`,
                            },
                        },
                        '&.base-map-icon': {
                            borderRadius: '8px',
                            backgroundColor: 'inherit',
                            color: baseColors.GRAY,
                            '&.selected': {
                                backgroundColor: baseColors.PRIMARY_MAIN,
                                color: baseColors.WHITE,
                            },
                        },
                    },
                },
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    root: {
                        '&.map-controller-checkbox-label': {
                            fontSize: '14px',
                            color: baseColors.WHITE,
                            '&.Mui-disabled': {
                                color: baseColors.GRAY,
                            },
                            '&:not(:last-child)': {
                                marginBottom: '8px',
                            },
                        },
                        '&.map-controller-radio-label': {
                            fontSize: '14px',
                            color: baseColors.WHITE,
                            '&.Mui-disabled': {
                                color: baseColors.GRAY,
                            },
                            '&:not(:last-child)': {
                                marginBottom: '8px',
                            },
                        },
                    },
                },
            },
            MuiGrid: {
                styleOverrides: {
                    root: {
                        '&.map-controller-menu': {
                            overflowX: 'auto',
                            flexWrap: 'nowrap',
                            '& div': {
                                flexShrink: 0,
                                '&:not(:last-child)': {
                                    marginRight: '15px',
                                },
                            },
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            },
                        },
                        '&.map-controller-submenu-item': {
                            fontSize: '14px',
                            '&:not(:last-child)': {
                                marginBottom: '8px',
                            },
                        },
                    },
                },
            },
            MuiPickersArrowSwitcher: {
                styleOverrides: {
                    root: {
                        '& .MuiIconButton-root': {
                            color: baseColors.WHITE,
                            '&.Mui-disabled': {
                                color: baseColors.GRAY,
                            },
                        },
                    },
                },
            },
            MuiPickersCalendarHeader: {
                styleOverrides: {
                    root: {
                        margin: 0,
                        padding: 0,
                    },
                    labelContainer: {
                        margin: '0 auto 0 0',
                    },
                    label: {
                        fontSize: '14px',
                        color: baseColors.WHITE,
                    },
                    switchViewButton: {
                        color: baseColors.WHITE,
                    },
                },
            },
            MuiCalendarPicker: {
                styleOverrides: {
                    root: {
                        backgroundColor: baseColors.PRIMARY_MAIN,
                        margin: 0,
                        width: '220px',
                    },
                },
            },
            MuiDayPicker: {
                styleOverrides: {
                    weekDayLabel: {
                        color: 'white',
                        width: '24px',
                        height: '24px',
                    },
                    header: {
                        justifyContent: 'space-between',
                    },
                    weekContainer: {
                        justifyContent: 'space-between',
                        margin: 0,
                    },
                },
            },
            MuiPickersDay: {
                styleOverrides: {
                    root: {
                        height: '24px',
                        width: '24px',
                        backgroundColor: `${baseColors.PRIMARY_MAIN} !important`,
                        color: baseColors.WHITE,
                        '&.Mui-disabled': {
                            color: baseColors.GRAY,
                        },
                        '&.Mui-selected': {
                            color: baseColors.PRIMARY_MAIN,
                            backgroundColor: `${baseColors.WHITE} !important`,
                        },
                    },
                    today: {
                        backgroundColor: baseColors.WHITE,
                        color: baseColors.GRAY,
                    },
                },
            },
            MuiPickersPopper: {
                styleOverrides: {
                    root: {
                        '& .MuiPaper-root': {
                            padding: 16,
                            backgroundColor: baseColors.PRIMARY_MAIN,
                        },
                    },
                },
            },
            PrivatePickersSlideTransition: {
                styleOverrides: {
                    root: {
                        minHeight: '150px !important',
                    },
                },
            },
            MuiCalendarOrClockPicker: {
                styleOverrides: {
                    root: {
                        width: '220px',
                    },
                },
            },
            PrivatePickersYear: {
                styleOverrides: {
                    root: {
                        color: `${baseColors.WHITE} !important`,
                    },
                },
            },
            MuiPickerStaticWrapper: {
                styleOverrides: {
                    root: {
                        width: '220px !important',
                    },
                    content: {
                        minWidth: '220px !important',
                        backgroundColor: 'unset',
                    },
                },
            },
            MuiTabs: {
                styleOverrides: {
                    scrollButtons: {
                        '&.Mui-disabled': {
                            opacity: 0.3,
                        },
                    },
                },
            },
        },
    };
};

const theme = createTheme(getThemeDefinition(BASE_COLORS));

const whiteLabelTheme = createTheme(getThemeDefinition(WHITE_LABEL_BASE_COLORS));
export { theme, whiteLabelTheme };

export default function getCenterPoint(geomObj) {
    let centerPoint;

    switch (geomObj.type) {
        case 'Point':
            centerPoint = geomObj.coordinates;
            break;
        case 'LineString': {
            const coords = geomObj.coordinates;
            const midIndex = Math.floor(coords.length / 2);
            centerPoint = coords[midIndex];
            break;
        }
        case 'Polygon': {
            // Get first ring of coordinates
            const coords = geomObj.coordinates[0];
            // Calculate average of all points
            const sumX = coords.reduce((sum, coord) => sum + coord[0], 0);
            const sumY = coords.reduce((sum, coord) => sum + coord[1], 0);
            centerPoint = [sumX / coords.length, sumY / coords.length];
            break;
        }
        case 'MultiPolygon': {
            // Get first polygon's first ring
            const coords = geomObj.coordinates[0][0];
            // Calculate average of all points
            const sumX = coords.reduce((sum, coord) => sum + coord[0], 0);
            const sumY = coords.reduce((sum, coord) => sum + coord[1], 0);
            centerPoint = [sumX / coords.length, sumY / coords.length];
            break;
        }
        default:
            throw new Error(`Unsupported geometry type: ${geomObj.type}`);
    }

    return {
        type: 'Point',
        coordinates: centerPoint,
    };
}

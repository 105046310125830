import { Grid } from '@mui/material';
import React from 'react';
import { DRAWING_STATES } from '../../constants/drawingStates';
import { useAppControl } from '../../providers/AppContext';
import PointAddButton from './buttons/PointAddButton';
import PointApplyButton from './buttons/PointApplyButton';
import PointCancelButton from './buttons/PointCancelButton';
import PointDeleteButton from './buttons/PointDeleteButton';

const RiskTakingPointDrawControls = () => {
    const { drawingState } = useAppControl();

    return (
        <Grid item xs={12} container direction="column">
            {drawingState === DRAWING_STATES.POINT_SETUP && (
                <>
                    <PointAddButton />
                    <PointDeleteButton />
                    <PointCancelButton />
                </>
            )}
            {drawingState === DRAWING_STATES.POINT && (
                <>
                    <PointApplyButton />
                    <PointCancelButton />
                </>
            )}
        </Grid>
    );
};

export default RiskTakingPointDrawControls;

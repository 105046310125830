import { SaveRounded } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useMap } from 'es-map-widget';
import React from 'react';
import { DRAWING_STATES, TOOLTIP_TEXTS } from '../../../constants';
import { useAppControl } from '../../../providers/AppContext';

const PointApplyButton = () => {
    const map = useMap();
    const { drawingState, setDrawingState, setDrawnPoints } = useAppControl();

    const handleClick = () => {
        const points = map.drawPointsSave();

        const newPoints = points.map((p) => {
            const point = {
                geom: {
                    type: 'Point',
                    coordinates: p.getGeometry().getCoordinates(),
                },
            };

            return point;
        });

        setDrawingState(DRAWING_STATES.NONE);
        setDrawnPoints(newPoints);
    };
    return (
        <Tooltip title={TOOLTIP_TEXTS.save} placement="right">
            <span>
                <IconButton
                    onClick={handleClick}
                    disabled={drawingState === DRAWING_STATES.POINT_YIELD}
                >
                    <SaveRounded />
                </IconButton>
            </span>
        </Tooltip>
    );
};

export default PointApplyButton;

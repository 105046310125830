import { ClearRounded, EditRounded } from '@mui/icons-material';
import {
    Autocomplete,
    Button,
    Grid,
    IconButton,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Fill, Stroke, useMap } from 'es-map-widget';
import { groupBy, uniqBy } from 'ramda';
import React, { useCallback, useEffect, useState } from 'react';
import HomeButton from '../../../components/atom/HomeButton';
import LinkAccordion from '../../../components/LinkAccordion';
import { MAP_LAYER_NAMES, OVERVIEW_STATES, OVERVIEW_TYPES } from '../../../constants';
import { DRAWING_STATES } from '../../../constants/drawingStates';
import { api as API, useApi } from '../../../hooks/useApi';
import { useAppControl } from '../../../providers/AppContext';
import { useLayout } from '../../../providers/LayoutContext';
import getCenterPoint from '../../../utils/geom.utils';
import formatCurrency from '../../../utils/number';

const RiskTakingSearch = () => {
    const { year } = useAppControl();

    const [plantSearchPhrase, setPlantSearchPhrase] = useState('');
    const [meparSearchPhrase, setMeparSearchPhrase] = useState('');

    const [selectedPlants, setSelectedPlants] = useState([]);
    const [selectedMepar, setSelectedMepar] = useState(null);
    const [selectedInsurancePackageTypes, setSelectedInsurancePackageTypes] = useState([]);
    const [selectedPoint, setSelectedPoint] = useState(null);
    const [scopeValue, setScopeValue] = useState('2');

    const [isCalculating, setIsCalculating] = useState(false);
    const [hasError, setHasError] = useState(false);

    const [filteredPolicies, setFilteredPolicies] = useState(null);
    const [filteredMepars, setFilteredMepars] = useState(null);
    const [filterScopeGeom, setFilterScopeGeom] = useState(null);

    const { drawingState, setDrawingState, setOverviewState, drawnPoints } = useAppControl();

    useEffect(() => {
        if (drawnPoints.length > 0) {
            setSelectedPoint(drawnPoints[0]);
        }
    }, [drawnPoints]);

    const map = useMap();
    const { matchesLgUp } = useLayout();

    const [totalInsuranceAmount, setTotalInsuranceAmount] = useState(null);
    const [nodes, setNodes] = useState([]);
    const [damageNodes, setDamageNodes] = useState([]);

    const [allPlants, allPlantsLoading] = useApi(
        (api) =>
            api.plants.searchPlants({
                phrase: encodeURIComponent(plantSearchPhrase) || undefined,
                limit: 10,
            }),
        [plantSearchPhrase]
    );

    const allInsurancePackageTypes = [
        'DT-A',
        'DT-BJ',
        'DT-BV',
        'DT-CF',
        'DT-CJ',
        'DT-CV',
        'DTBTF',
        'DTBŐF',
        'ETÁB',
        'HENB',
        'HJKB',
        'HTFB',
        'HVKB',
        'HŐFB',
        'JKB-I',
        'JVTKB',
        'RIASZ',
        'VKB',
    ];

    const [allMepars, allMeparsLoading] = useApi(
        (api) =>
            api.blocks.searchBlocks({
                phrase: encodeURIComponent(meparSearchPhrase) || undefined,
                limit: 10,
            }),
        [meparSearchPhrase]
    );

    const isValid = (selectedPoint || selectedMepar) && scopeValue !== '';

    const resetState = () => {
        setFilteredPolicies(null);
        setFilteredMepars(null);
        setFilterScopeGeom(null);
        setTotalInsuranceAmount(null);
        setNodes(null);
        setHasError(false);
        map.setLayerVisibility(MAP_LAYER_NAMES.MEPAR, false);
    };

    const assignPolicyProperties = (policies) => {
        policies.forEach((policy) => {
            policy.policyPlots.forEach((policyPlot) => {
                // eslint-disable-next-line no-param-reassign
                policyPlot.policyNumber = policy.policyNumber;
            });
            policy.damages.forEach((damage) => {
                damage.damagePlots.forEach((damagePlot) => {
                    // eslint-disable-next-line no-param-reassign
                    damagePlot.damageNumber = damage.damageNumber;
                });
            });
        });
    };

    const splitInsuranceAmountsForPolicies = (policies) => {
        policies.forEach((policy) => {
            const policyPlants = policy.policyPlots
                .filter((plot) =>
                    selectedPlants.some((plant) => plant.plantCode === plot.plantCode)
                )
                .map((plot) => {
                    return { plantCode: plot.plantCode };
                });

            (selectedPlants.length > 0 ? policyPlants : policy.policyPlots).forEach((plant) => {
                const insuranceAmount = policy.policyInsuranceAmounts.find(
                    (insuranceAmountCurrent) => {
                        if (
                            selectedInsurancePackageTypes.length > 0 &&
                            !selectedInsurancePackageTypes.some((type) =>
                                insuranceAmountCurrent.insurancePackageType.includes(type)
                            )
                        ) {
                            return false;
                        }
                        return insuranceAmountCurrent.plantCode === plant.plantCode;
                    },
                    null
                );

                if (!insuranceAmount) return;

                const totalPlantArea = policy.policyPlots
                    .filter((plot) => plot.plantCode === insuranceAmount.plantCode)
                    .reduce((acc, plot) => acc + (plot.insuredArea || 0), 0);

                policy.policyPlots
                    .filter((plot) => plot.plantCode === insuranceAmount.plantCode)
                    .forEach((plot) => {
                        const insuredAmount =
                            plot.insuredArea &&
                            totalPlantArea &&
                            insuranceAmount.totalInsuranceAmount
                                ? (plot.insuredArea / totalPlantArea) *
                                  insuranceAmount.totalInsuranceAmount
                                : 0;

                        // eslint-disable-next-line no-param-reassign
                        plot.insuredAmount = insuredAmount;
                    });
            });
        });
        return policies;
    };

    const calculateTotalInsuranceAmount = (policies, mepars) => {
        return policies.reduce((total, policy) => {
            return (
                total +
                policy.policyPlots.reduce((acc, policyPlot) => {
                    if (
                        mepars.some((mepar) => mepar.meparCode === policyPlot.meparCode) &&
                        (selectedPlants.length > 0
                            ? selectedPlants
                                  .map((plant) => plant.plantCode)
                                  .includes(policyPlot.plantCode)
                            : true) &&
                        policyPlot.insuredAmount
                    ) {
                        return acc + policyPlot.insuredAmount;
                    }
                    return acc;
                }, 0)
            );
        }, 0);
    };

    const handleFilter = async () => {
        if (!isValid) {
            setHasError(true);
            return;
        }
        setIsCalculating(true);
        const { data: response } = await API.policies.filterPolicies({
            scope: scopeValue,
            geom: encodeURIComponent(
                selectedPoint
                    ? JSON.stringify(selectedPoint.geom)
                    : JSON.stringify(getCenterPoint(selectedMepar.geom))
            ),
            plantCodes: selectedPlants.map((plant) => plant.plantCode),
            year,
        });

        setFilteredPolicies(response.policies);
        setFilteredMepars(response.mepars);
        setFilterScopeGeom(JSON.parse(response.scope));
        setIsCalculating(false);
    };

    const zoomToMepar = useCallback(
        (child) => {
            if (!child.geom) return;
            map.zoomToGeomsExtent(
                [{ geom: child.geom }],
                !matchesLgUp ? { padding: [0, 0, 0, 0] } : { padding: [50, 100, 400, 100] }
            );
        },
        [map]
    );

    useEffect(() => {
        if (!map || !filteredPolicies || !filteredMepars || !filterScopeGeom) return;

        assignPolicyProperties(filteredPolicies);

        const policiesWithInsuranceAmounts = splitInsuranceAmountsForPolicies(filteredPolicies);
        const calculatedTotalInsuranceAmount = calculateTotalInsuranceAmount(
            policiesWithInsuranceAmounts,
            filteredMepars
        );

        setTotalInsuranceAmount(calculatedTotalInsuranceAmount);

        const damageNodeList = Object.entries(
            groupBy(
                (e) => e.meparCode,
                policiesWithInsuranceAmounts
                    .flatMap((e) => e.damages)
                    .flatMap((e) => e.damagePlots)
                    .filter(
                        (damagePlot) =>
                            (selectedPlants.length > 0
                                ? selectedPlants.some(
                                      (plant) => plant.plantName === damagePlot.plantCode
                                  )
                                : true) &&
                            filteredMepars.some((mepar) => mepar.meparCode === damagePlot.meparCode)
                    )
            )
        ).map(([code, data]) => ({
            code,
            name: code,
            children: data.map((damagePlot) => ({
                code: damagePlot.id,
                name: `${damagePlot.damageNumber} - ${damagePlot.plantCode} - ${damagePlot.damagedArea} ha`,
                type: OVERVIEW_TYPES.POLICY,
                meparCode: damagePlot.meparCode,
                geom: damagePlot.mepar?.geom,
            })),
        }));

        setDamageNodes(damageNodeList);

        const nodesFromResult = Object.entries(
            groupBy(
                (e) => e.meparCode,
                policiesWithInsuranceAmounts
                    .flatMap((e) => e.policyPlots)
                    .filter(
                        (policyPlot) =>
                            (selectedPlants.length > 0
                                ? selectedPlants.some(
                                      (plant) => plant.plantCode === policyPlot.plantCode
                                  )
                                : true) &&
                            filteredMepars.some((mepar) => mepar.meparCode === policyPlot.meparCode)
                    )
            )
        ).map(([code, data]) => ({
            code,
            name: code,
            children: data.map((policyPlot) => ({
                code: policyPlot.id,
                name: `${policyPlot.policyNumber} - ${policyPlot.plantName} (${
                    policyPlot.plantCode
                }) - ${policyPlot.insuredArea} ha - ${formatCurrency(policyPlot.insuredAmount)}`,
                type: OVERVIEW_TYPES.POLICY,
                meparCode: policyPlot.meparCode,
                geom: policyPlot.mepar?.geom,
                policyNumber: policyPlot.policyNumber,
                plantCode: policyPlot.plantCode,
                insuredArea: policyPlot.insuredAmount,
            })),
        }));

        setNodes(nodesFromResult);

        const filterScopeGeomStyle = {
            stroke: new Stroke({
                color: '#FFFFFF',
                width: 1,
            }),
            fill: new Fill({
                color: [255, 255, 255, 0.1],
            }),
        };

        const policyGeoms = uniqBy(
            (x) => x.meparCode,
            nodesFromResult.flatMap((e) => e.children)
        )
            .filter((e) => e.geom)
            .map((policy) => ({
                geom: policy.geom,
                layerName: policy.meparCode,
            }));

        map.setLayerVisibility(MAP_LAYER_NAMES.MEPAR, true);
        map.setLayerData(MAP_LAYER_NAMES.MEPAR, {
            geoms: [
                ...policyGeoms,
                {
                    geom: filterScopeGeom,
                    layerName: '',
                    style: filterScopeGeomStyle,
                },
                selectedMepar
                    ? {
                          geom: selectedMepar.geom,
                          layerName: selectedMepar.meparCode,
                          style: {
                              stroke: new Stroke({
                                  color: '#FF0000',
                                  width: 2,
                              }),
                          },
                      }
                    : null,
            ].filter(Boolean),
        });
        if (policyGeoms.length > 0) {
            map.zoomToGeomsExtent(policyGeoms, !matchesLgUp ? { padding: [0, 0, 0, 0] } : {});
        }
    }, [map, filteredPolicies, filteredMepars, filterScopeGeom, zoomToMepar]);

    return (
        <Grid container item xs={12} direction="column" gap={1} sx={{ mb: 1, width: '100%' }}>
            <Grid item container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h4">Kockázatvállalás</Typography>
                </Grid>
                <Grid item>
                    <HomeButton />
                </Grid>
            </Grid>
            <Grid item container sx={{ width: '100%', mt: 2 }}>
                <Stack spacing={2} direction="column" sx={{ width: '100%', flexGrow: 1 }}>
                    <Autocomplete
                        multiple
                        options={allPlants || []}
                        getOptionLabel={(option) => `${option.plantName} (${option.plantCode})`}
                        value={selectedPlants}
                        onChange={(_, newValue) => {
                            setSelectedPlants(newValue);
                            resetState();
                        }}
                        loading={allPlantsLoading}
                        onInputChange={(_, newInputValue) => setPlantSearchPhrase(newInputValue)}
                        noOptionsText="Nincs találat"
                        isOptionEqualToValue={(option, value) =>
                            value && typeof value === 'object' && option.id === value.id
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Növény"
                                placeholder={
                                    selectedPlants.length === 0
                                        ? 'Válasszon növényfajtát'
                                        : undefined
                                }
                                error={hasError && selectedPlants.length === 0}
                                helperText={
                                    hasError && selectedPlants.length === 0 ? 'Kötelező mező' : ''
                                }
                                fullWidth
                            />
                        )}
                    />
                    <Autocomplete
                        multiple
                        options={allInsurancePackageTypes || []}
                        value={selectedInsurancePackageTypes}
                        onChange={(_, newValue) => {
                            setSelectedInsurancePackageTypes(newValue);
                            resetState();
                        }}
                        noOptionsText="Nincs találat"
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Kockázati mód"
                                placeholder={
                                    selectedInsurancePackageTypes.length === 0
                                        ? 'Válasszon kockázati módot'
                                        : undefined
                                }
                                error={hasError && selectedInsurancePackageTypes.length === 0}
                                helperText={
                                    hasError && selectedInsurancePackageTypes.length === 0
                                        ? 'Kötelező mező'
                                        : ''
                                }
                                fullWidth
                            />
                        )}
                    />
                    <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Autocomplete
                            fullWidth
                            disabled={!!selectedPoint}
                            options={allMepars || []}
                            getOptionLabel={(option) => option.meparCode}
                            value={selectedMepar}
                            onChange={(_, newValue) => {
                                setSelectedMepar(newValue);
                                resetState();
                            }}
                            loading={allMeparsLoading}
                            onInputChange={(_, newInputValue) =>
                                setMeparSearchPhrase(newInputValue)
                            }
                            noOptionsText="Nincs találat"
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Mepar"
                                    placeholder="Válasszon mepar kódot"
                                    error={hasError && !selectedMepar}
                                    helperText={hasError && !selectedMepar ? 'Kötelező mező' : ''}
                                    fullWidth
                                />
                            )}
                        />
                        <IconButton
                            onClick={() => {
                                setSelectedMepar(null);
                                setDrawingState(DRAWING_STATES.POINT);
                                setOverviewState(OVERVIEW_STATES.HIDDEN);
                                map.drawPoints([]);
                            }}
                            disabled={drawingState !== DRAWING_STATES.NONE}
                        >
                            <EditRounded />
                        </IconButton>
                    </Stack>
                    <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        {selectedPoint && (
                            <Typography variant="body1">
                                Koordináta:{' '}
                                {selectedPoint.geom.coordinates
                                    .map((coord) => coord.toFixed(2))
                                    .join(', ')}
                            </Typography>
                        )}
                        {selectedPoint && (
                            <IconButton
                                onClick={() => {
                                    setSelectedPoint(null);
                                    resetState();
                                }}
                                disabled={drawingState !== DRAWING_STATES.NONE}
                            >
                                <ClearRounded />
                            </IconButton>
                        )}
                    </Stack>
                    <TextField
                        label="Hatókör (km)"
                        type="number"
                        value={scopeValue}
                        onChange={(e) => {
                            setScopeValue(e.target.value);
                            resetState();
                        }}
                        placeholder="Adja meg a hatókört"
                        error={hasError && scopeValue === ''}
                        helperText={hasError && scopeValue === '' ? 'Kötelező mező' : ''}
                        fullWidth
                    />
                    <Button
                        variant="contained"
                        onClick={handleFilter}
                        disabled={!isValid || isCalculating}
                        sx={{ alignSelf: 'flex-end' }}
                        startIcon={isCalculating ? <CircularProgress size={20} /> : null}
                    >
                        Szűrés
                    </Button>
                </Stack>
                <Stack sx={{ mt: 2 }}>
                    {totalInsuranceAmount !== null && (
                        <Typography variant="body1">
                            Biztosítási összeg:{' '}
                            <strong>{formatCurrency(totalInsuranceAmount)}</strong>
                        </Typography>
                    )}
                </Stack>
            </Grid>
            <Grid item>
                {filteredPolicies &&
                    filteredMepars &&
                    filterScopeGeom &&
                    nodes &&
                    nodes.length > 0 && (
                        <LinkAccordion
                            nodes={nodes}
                            linkTo={OVERVIEW_TYPES.MEPAR}
                            title="Biztosított meparok"
                            openOverview={false}
                            cb={zoomToMepar}
                        />
                    )}
                {filteredPolicies &&
                    filteredMepars &&
                    filterScopeGeom &&
                    damageNodes &&
                    damageNodes.length > 0 && (
                        <LinkAccordion
                            nodes={damageNodes}
                            linkTo={OVERVIEW_TYPES.MEPAR}
                            title="Kárakták"
                            openOverview={false}
                            cb={zoomToMepar}
                        />
                    )}
            </Grid>
        </Grid>
    );
};

export default RiskTakingSearch;

import { ThemeProvider } from '@mui/material';
import React from 'react';
import { theme, whiteLabelTheme } from '../theme';
import { useAuth } from './AuthProvider';

export default function AppThemeProvider({ children }) {
    const { user } = useAuth();
    return (
        <ThemeProvider theme={user?.isWhiteLabel ? whiteLabelTheme : theme}>
            {children}
        </ThemeProvider>
    );
}

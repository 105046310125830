import { CancelRounded } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useMap } from 'es-map-widget';
import React from 'react';
import { DRAWING_STATES, TOOLTIP_TEXTS } from '../../../constants';
import { useAppControl } from '../../../providers/AppContext';

const PointCancelButton = ({ disabled }) => {
    const map = useMap();
    const { setDrawingState } = useAppControl();

    const handleClick = () => {
        map.drawCancel();
        setDrawingState(DRAWING_STATES.NONE);
    };

    return (
        <Tooltip title={TOOLTIP_TEXTS.cancel} placement="right">
            <span>
                <IconButton onClick={handleClick} disabled={disabled}>
                    <CancelRounded />
                </IconButton>
            </span>
        </Tooltip>
    );
};

export default PointCancelButton;
